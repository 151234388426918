<template>
    <p>Logging in.....</p>
</template>

<script>
export default {
    name:"OtpVerify",
    data(){
      return {
        otp:""
      }
    },
    created(){
      if(this.$route.query.token) {
          localStorage.token = this.$route.query.token;
          this.toast.success("Logged in successfully");
          this.$router.replace("/pages/dashboard");
      } else {
        this.$router.replace("?error=Invalid Token");
      }
    },
    methods:{

    }
}
</script>

<style scoped>

</style>